import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { CollapsePanelModule } from '@logic-suite/shared/components/collapse-panel';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { CollapsePanelComponent } from '@suite/collapse-panel';
import { Favorite, FavoriteService } from '@suite/favorite';
import { FavoriteHierarchyComponent } from '@suite/favorite-hierarchy';
import { FullHierarchyComponent } from '@suite/full-hierarchy';
import { HierarchyNode, HierarchyService } from '@suite/hierarchy-data-access';
import { RouterService } from '@suite/router';
import { ButtonModule } from 'primeng/button';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { TabMenuModule } from 'primeng/tabmenu';
import { TreeModule } from 'primeng/tree';

type HierarchyType = 'full' | 'favorites' | 'search';

@Component({
    selector: 'lib-hierarchy',
    imports: [
        CommonModule,
        TreeModule,
        CardModule,
        ButtonModule,
        CdkTreeModule,
        TranslateModule,
        FullHierarchyComponent,
        TabMenuModule,
        FavoriteHierarchyComponent,
        ButtonGroupModule,
        SvgIconComponent,
        CollapsePanelModule,
        CollapsePanelComponent,
        DividerModule,
    ],
    templateUrl: './hierarchy.component.html',
    styleUrl: './hierarchy.component.scss'
})
export class HierarchyComponent implements OnInit {
  router = inject(Router);
  routerService = inject(RouterService);
  favoriteService = inject(FavoriteService);
  hierarchyService = inject(HierarchyService);

  selectedTab = signal<HierarchyType>('favorites');

  showConfigMenu = computed(() => this.routerService.currentUrl().startsWith('/config'));
  // TODO(bjhandeland): Add this to route metadata.
  hasHierarchy = computed(
    () =>
      this.routerService.currentUrl().startsWith('/energy') ||
      this.routerService.currentUrl().startsWith('/config') ||
      this.routerService.currentUrl().startsWith('/sense') ||
      this.routerService.currentUrl().startsWith('/facility') ||
      this.routerService.currentUrl().startsWith('/advise') ||
      this.routerService.currentUrl().startsWith('/asset-log') ||
      this.routerService.currentUrl().startsWith('/co2-config'),
  );

  ngOnInit() {
    if (this.favoriteService.favorites().size === 0) {
      this.selectedTab.set('full');
    }
  }

  toggleFavorite(hierarchyNode: HierarchyNode) {
    const favorite: Favorite = {
      id: hierarchyNode.getUniqueId(),
      type: hierarchyNode.type,
    };
    this.favoriteService.toggleFavorite$.next(favorite);
  }

  selectNode(hierarchyNode: HierarchyNode) {
    const queryParams = hierarchyNode.getQueryParams();
    this.router.navigate([], { queryParams });
  }
}
