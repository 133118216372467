import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ThemeSwitchComponent } from '@logic-suite/shared';
import {
  accentPalette,
  graphPalette,
  greenPalette,
  greyPalette,
  newPalette,
  primaryPalette,
  vibrantPalette,
  warningPalette,
} from '@logic-suite/shared/utils';
import { ChartsModule } from '../../shared/chart/chart.module';

@Component({
    selector: 'app-palette',
    imports: [CommonModule, ThemeSwitchComponent, MatCheckboxModule, ChartsModule],
    templateUrl: './palette.component.html',
    styleUrls: ['./palette.component.scss']
})
export class PaletteComponent {
  primary = primaryPalette;
  accent = accentPalette;
  warning = warningPalette;
  green = greenPalette;
  grey = greyPalette;
  graph = graphPalette;
  vibrant = vibrantPalette;
  newColors = newPalette;

  selectedPalette = signal('vibrant');

  chart: Highcharts.Options = {
    chart: { type: 'column' },
    title: { text: undefined },
    credits: { enabled: false },
    xAxis: { type: 'datetime' },
    series: [],
  };
}
