import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideSvgIcons, provideSvgIconsConfig, SvgIconComponent } from '@ngneat/svg-icon';
import { asAclIcon } from './icons/acl';
import { asBaseLoadIcon } from './icons/base-load';
import { asBellIcon } from './icons/bell';
import { asBuilding01Icon } from './icons/building-01';
import { asBuilding02Icon } from './icons/building-02';
import { asBuilding03Icon } from './icons/building-03';
import { asChatBubbleIcon } from './icons/chat-bubble';
import { asCheckIcon } from './icons/check';
import { asChevronDownIcon } from './icons/chevron-down';
import { asChevronLeftIcon } from './icons/chevron-left';
import { asChevronRightIcon } from './icons/chevron-right';
import { asChevronUpIcon } from './icons/chevron-up';
import { asChipIcon } from './icons/chip';
import { asClipboardIcon } from './icons/clipboard';
import { asCogIcon } from './icons/cog';
import { asCoolingIcon } from './icons/cooling';
import { asCrossIcon } from './icons/cross';
import { asCustomerIcon } from './icons/customer';
import { asDotIcon } from './icons/dot';
import { asDropletsIcon } from './icons/droplets';
import { asEditBoxIcon } from './icons/edit-box';
import { asEnergyIcon } from './icons/energy';
import { asEtDeviationIcon } from './icons/et-deviation';
import { asFloorIcon } from './icons/floor';
import { asFlowIcon } from './icons/flow';
import { asGasIcon } from './icons/gas';
import { asHandleChevronLeftIcon } from './icons/handle-chevron-left';
import { asHandleChevronRightIcon } from './icons/handle-chevron-right';
import { asHandleHorizontalIcon } from './icons/handle-horizontal';
import { asHeatingIcon } from './icons/heating';
import { asHelpSquareIcon } from './icons/help-square';
import { asListIcon } from './icons/list';
import { asMeterIcon } from './icons/meter';
import { asMeterGroupIcon } from './icons/meter-group';
import { asMoneyIcon } from './icons/money';
import { asNoovaLogoIcon } from './icons/noova-logo';
import { asPeakLoadIcon } from './icons/peak-load';
import { asPencilIcon } from './icons/pencil';
import { asPlusIcon } from './icons/plus';
import { asResourceIcon } from './icons/resource';
import { asRoomIcon } from './icons/room';
import { asSearchIcon } from './icons/search';
import { asSectionIcon } from './icons/section';
import { asStarIcon } from './icons/star';
import { asStarFilledIcon } from './icons/star-filled';
import { asSubsubMeterIcon } from './icons/subsub-meter';
import { asSubsubsubMeterIcon } from './icons/subsubsub-meter';
import { asSwitchArrowsIcon } from './icons/switch-arrows';
import { asThermometerIcon } from './icons/thermometer';
import { asTrashIcon } from './icons/trash';
import { asUsersIcon } from './icons/users';
import { asVirtualMeterIcon } from './icons/virtual-meter';
import { asWaterIcon } from './icons/water';
import { asZoneIcon } from './icons/zone';
import { asWaterSubIcon } from './icons/water-sub';
import { asWaterSub2Icon } from './icons/water-sub-2';
import { asWaterSub3Icon } from './icons/water-sub-3';
import { asWaterSub4Icon } from './icons/water-sub-4';
import { asWaterSub5Icon } from './icons/water-sub-5';
import { asColdWaterSubIcon } from './icons/cold-water-sub';
import { asColdWaterSub2Icon } from './icons/cold-water-sub-2';
import { asColdWaterSub3Icon } from './icons/cold-water-sub-3';
import { asColdWaterSub4Icon } from './icons/cold-water-sub-4';
import { asColdWaterSub5Icon } from './icons/cold-water-sub-5';
import { asHotWaterIcon } from './icons/hot-water';
import { asHotWaterSubIcon } from './icons/hot-water-sub';
import { asHotWaterSub2Icon } from './icons/hot-water-sub-2';
import { asHotWaterSub3Icon } from './icons/hot-water-sub-3';
import { asHotWaterSub4Icon } from './icons/hot-water-sub-4';
import { asHotWaterSub5Icon } from './icons/hot-water-sub-5';
import { asCoolingSubIcon } from './icons/cooling-sub';
import { asCoolingSub2Icon } from './icons/cooling-sub-2';
import { asCoolingSub3Icon } from './icons/cooling-sub-3';
import { asCoolingSub4Icon } from './icons/cooling-sub-4';
import { asCoolingSub5Icon } from './icons/cooling-sub-5';
import { asGasSub2Icon } from './icons/gas-sub-2';
import { asGasSubIcon } from './icons/gas-sub';
import { asGasSub3Icon } from './icons/gas-sub-3';
import { asGasSub4Icon } from './icons/gas-sub-4';
import { asGasSub5Icon } from './icons/gas-sub-5';
import { asHeatingSubIcon } from './icons/heating-sub';
import { asHeatingSub2Icon } from './icons/heating-sub-2';
import { asHeatingSub3Icon } from './icons/heating-sub-3';
import { asHeatingSub4Icon } from './icons/heating-sub-4';
import { asHeatingSub5Icon } from './icons/heating-sub-5';
import { asProductionMeterIcon } from './icons/production-meter';
import { asProductionMeterSubIcon } from './icons/production-meter-sub';
import { asProductionMeterSub2Icon } from './icons/production-meter-sub-2';
import { asProductionMeterSub3Icon } from './icons/production-meter-sub-3';
import { asProductionMeterSub4Icon } from './icons/production-meter-sub-4';
import { asProductionMeterSub5Icon } from './icons/production-meter-sub-5';
import { asColdWaterIcon } from './icons/cold-water';
import { asMeterSubIcon } from './icons/meter-sub';
import { asMeterSub3Icon } from './icons/meter-sub-3';
import { asMeterSub4Icon } from './icons/meter-sub-4';
import { asMeterSub5Icon } from './icons/meter-sub-5';
import { asMeterSub2Icon } from './icons/meter-sub-2';
import { asKpiIcon } from './icons/kpi';

/**
 * Manages all icons in the suite.
 *
 * To add an icon, first add the desired svg to ./assets.
 * Then run `bun run svg` to generate the ts icon.
 * Then add your icon to `provideSvgIcons` below.
 * You can then use icons like:
 *
 * ```html
 * <svg-icon key="star"></svg-icon>
 * ```
 */
@NgModule({
  imports: [CommonModule, SvgIconComponent],
  providers: [
    provideSvgIconsConfig({
      sizes: {
        xs: '16px',
        sm: '20px',
        md: '24px',
        lg: '28px',
        xl: '32px',
        xxl: '36px',
      },
      defaultSize: 'md',
    }),
    provideSvgIcons([
      asAclIcon,
      asBaseLoadIcon,
      asBellIcon,
      asBuilding01Icon,
      asBuilding02Icon,
      asBuilding03Icon,
      asChatBubbleIcon,
      asCheckIcon,
      asChevronDownIcon,
      asChevronLeftIcon,
      asChevronRightIcon,
      asChevronUpIcon,
      asChipIcon,
      asClipboardIcon,
      asCogIcon,
      asColdWaterIcon,
      asColdWaterSub2Icon,
      asColdWaterSub3Icon,
      asColdWaterSub4Icon,
      asColdWaterSub5Icon,
      asColdWaterSubIcon,
      asCoolingIcon,
      asCoolingSub2Icon,
      asCoolingSub3Icon,
      asCoolingSub4Icon,
      asCoolingSub5Icon,
      asCoolingSubIcon,
      asCrossIcon,
      asCustomerIcon,
      asDotIcon,
      asDropletsIcon,
      asEditBoxIcon,
      asEnergyIcon,
      asEtDeviationIcon,
      asFloorIcon,
      asFlowIcon,
      asGasIcon,
      asGasSub2Icon,
      asGasSub3Icon,
      asGasSub4Icon,
      asGasSub5Icon,
      asGasSubIcon,
      asHandleChevronLeftIcon,
      asHandleChevronRightIcon,
      asHandleHorizontalIcon,
      asHeatingIcon,
      asHeatingSub2Icon,
      asHeatingSub3Icon,
      asHeatingSub4Icon,
      asHeatingSub5Icon,
      asHeatingSubIcon,
      asHelpSquareIcon,
      asHotWaterIcon,
      asHotWaterSub2Icon,
      asHotWaterSub3Icon,
      asHotWaterSub4Icon,
      asHotWaterSub5Icon,
      asHotWaterSubIcon,
      asKpiIcon,
      asListIcon,
      asMeterGroupIcon,
      asMeterIcon,
      asMeterSub2Icon,
      asMeterSub3Icon,
      asMeterSub4Icon,
      asMeterSub5Icon,
      asMeterSubIcon,
      asMoneyIcon,
      asNoovaLogoIcon,
      asPeakLoadIcon,
      asPencilIcon,
      asPlusIcon,
      asProductionMeterIcon,
      asProductionMeterSub2Icon,
      asProductionMeterSub3Icon,
      asProductionMeterSub4Icon,
      asProductionMeterSub5Icon,
      asProductionMeterSubIcon,
      asResourceIcon,
      asRoomIcon,
      asSearchIcon,
      asSectionIcon,
      asStarFilledIcon,
      asStarIcon,
      asSubsubMeterIcon,
      asSubsubsubMeterIcon,
      asSwitchArrowsIcon,
      asThermometerIcon,
      asTrashIcon,
      asUsersIcon,
      asVirtualMeterIcon,
      asWaterIcon,
      asWaterSub2Icon,
      asWaterSub3Icon,
      asWaterSub4Icon,
      asWaterSub5Icon,
      asWaterSubIcon,
      asZoneIcon,
    ]),
  ],
  exports: [SvgIconComponent],
})
export class IconModule {}
