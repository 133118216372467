import { ChangeDetectionStrategy, Component, input, output, ViewChild } from '@angular/core';
import { CommonModule, KeyValue } from '@angular/common';
import { CdkNestedTreeNode, CdkTree, CdkTreeNodeDef, CdkTreeNodeOutlet } from '@angular/cdk/tree';
import { HierarchyNodeComponent } from '@suite/hierarchy-node';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { HierarchyNode, HierarchyNodeType } from '@suite/hierarchy-data-access';
import { Favorite } from '@suite/favorite';

@Component({
    selector: 'lib-favorite-hierarchy',
    imports: [
        CommonModule,
        CdkNestedTreeNode,
        CdkTree,
        CdkTreeNodeDef,
        CdkTreeNodeOutlet,
        HierarchyNodeComponent,
        TranslateModule,
        RouterLink,
    ],
    templateUrl: './favorite-hierarchy.component.html',
    styleUrl: './favorite-hierarchy.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteHierarchyComponent {
  hierarchy = input.required<HierarchyNode[]>();
  favorites = input<Map<string, Favorite>>(new Map<string, Favorite>());
  selectedNode = input<HierarchyNode | null>(null);
  showConfigMenu = input<boolean>();
  selectNode = output<HierarchyNode>();
  toggleFavorite = output<HierarchyNode>();

  private readonly nodeTypeOrder: HierarchyNodeType[] = [
    'assetGroup',
    'asset',
    'section',
    'level',
    'room',
    'zone',
    'resource',
    'assetMeteringPointGroup',
    'meteringPoint',
    'virtualMeteringPoint',
  ];

  childrenAccessor = (hierarchyNode: HierarchyNode) => hierarchyNode.children ?? [];

  hasChild = (_: number, hierarchyNode: HierarchyNode) => hierarchyNode.hasChild();

  @ViewChild('favoriteTree') favoriteTree!: CdkTree<HierarchyNode>;

  compareNodeTypes = (
    a: KeyValue<HierarchyNodeType, HierarchyNode[]>,
    b: KeyValue<HierarchyNodeType, HierarchyNode[]>,
  ): number => {
    const indexA = this.nodeTypeOrder.indexOf(a.key);
    const indexB = this.nodeTypeOrder.indexOf(b.key);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    } else {
      return a.key.localeCompare(b.key);
    }
  };

  private findDirectFavorites(
    nodes: HierarchyNode[],
    favorites: Map<string, Favorite>,
  ): Map<HierarchyNodeType, HierarchyNode[]> {
    const result = new Map<HierarchyNodeType, HierarchyNode[]>();

    const addToResult = (node: HierarchyNode) => {
      if (!result.has(node.type)) {
        result.set(node.type, []);
      }
      result.get(node.type)!.push(node);
    };

    for (const node of nodes) {
      if (favorites.has(`${node.type}:${node.getUniqueId()}`)) {
        addToResult(node);
      }
      // Recursively check children
      this.findDirectFavorites(node.children, favorites).forEach((childNodes, childType) => {
        childNodes.forEach((childNode) => addToResult(childNode));
      });
    }

    // Sort nodes within each type
    result.forEach((nodes, type) => {
      nodes.sort((a, b) => a.name.localeCompare(b.name));
    });

    return result;
  }

  getFavoriteNodes(): Map<HierarchyNodeType, HierarchyNode[]> {
    const favorites = this.favorites();
    const unsortedMap = this.findDirectFavorites(this.hierarchy(), favorites);

    // Create a new sorted map
    const sortedMap = new Map<HierarchyNodeType, HierarchyNode[]>();

    // Add entries in the specified order
    this.nodeTypeOrder.forEach((type) => {
      if (unsortedMap.has(type)) {
        sortedMap.set(type, unsortedMap.get(type)!);
      }
    });

    return sortedMap;
  }
}
