import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TelemetryModule } from '../telemetry/telemetry.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, TelemetryModule, MatSnackBarModule],
  // TODO: This yields a circular dependency for some reason after upgrade to Angular 19
  // But it should be replaced with the errorhandler in Sentry instead anyway
  // providers: [
  //   {
  //     // processes all errors
  //     provide: ErrorHandler,
  //     // useClass: getEnv('env') !== 'dev' ? GlobalErrorHandler : ErrorHandler,
  //     useClass: GlobalErrorHandler,
  //   },
  //   { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  // ],
  exports: [TelemetryModule],
})
export class ErrorHandlerModule {}
